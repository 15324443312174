var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drugProd-wrap", staticStyle: { padding: "1rem" } },
    [
      _c(
        "div",
        {
          staticStyle: {
            background: "#FFFFFF",
            "padding-left": "20px",
            "margin-bottom": "10px",
          },
        },
        [
          _vm.reload
            ? _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.jump },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _vm.tabs[0].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[0].title, name: "0" },
                      })
                    : _vm._e(),
                  _vm.tabs[1].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[1].title, name: "1" },
                      })
                    : _vm._e(),
                  _vm.tabs[2].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[2].title, name: "2" },
                      })
                    : _vm._e(),
                  _vm.tabs[3].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[3].title, name: "3" },
                      })
                    : _vm._e(),
                  _vm.tabs[4].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[4].title, name: "4" },
                      })
                    : _vm._e(),
                  _vm.tabs[5].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[5].title, name: "5" },
                      })
                    : _vm._e(),
                  _vm.tabs[6].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[6].title, name: "6" },
                      })
                    : _vm._e(),
                  _vm.tabs[7].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[7].title, name: "7" },
                      })
                    : _vm._e(),
                  _vm.tabs[8].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[8].title, name: "8" },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "scrollContent",
          staticClass: "scroll-content recordContent",
          style:
            "overflow-x: hidden; overflow-y: auto;height:" +
            _vm.contentStyleObj.height,
          on: { scroll: _vm.onScroll },
        },
        [
          _c(
            "el-row",
            { staticClass: "substanceName" },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "padding-left": "15px" },
                  attrs: { span: 24 },
                },
                [
                  _c("span", { staticStyle: { "margin-right": "5px" } }, [
                    _vm._v(_vm._s(_vm.drugBasicObj.defaultName)),
                  ]),
                  _c("span", { staticStyle: { "margin-right": "5px" } }, [
                    _vm._v("(id: " + _vm._s(_vm.drugProdId) + ")"),
                  ]),
                  _c("code-icon", {
                    attrs: { iconCode: _vm.classifyObj.classifyIcon, size: 26 },
                  }),
                  _vm._l(_vm.classifyIcons, function (item, index) {
                    return _c("code-icon", {
                      key: index,
                      staticStyle: { "margin-left": "5px" },
                      attrs: { iconCode: item, size: 26 },
                    })
                  }),
                  Number(_vm.drugProdId) === 8024
                    ? _c(
                        "el-tooltip",
                        {
                          staticClass: "fr",
                          attrs: {
                            content: "用药指导",
                            placement: "top",
                            effect: "light",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "none-hightlight",
                              staticStyle: {
                                border: "none",
                                padding: "0",
                                margin: "8px 20px 0 0",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toDrugUseInstruction()
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticStyle: { width: "22px", height: "22px" },
                                attrs: { "icon-class": "drug-icon" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  Number(_vm.drugProdId) === 8024
                    ? _c(
                        "el-tooltip",
                        {
                          staticClass: "fr",
                          attrs: {
                            content: "药品说明书",
                            placement: "top",
                            effect: "light",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "none-hightlight",
                              staticStyle: {
                                border: "none",
                                padding: "0",
                                margin: "10px 15px 0",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "/inlyta.html",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    staticStyle: {
                                      width: "26px",
                                      height: "20px",
                                    },
                                    attrs: {
                                      "icon-class": "drug-instructions",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[0].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure bacg-el-row" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("药品名称")])]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "content bacg" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: { model: _vm.form, "label-width": "0px" },
                        },
                        _vm._l(_vm.dkbDpAliasList, function (item) {
                          return _c("el-form-item", { key: item.id }, [
                            _c(
                              "li",
                              { staticClass: "background-li el-form-item-li" },
                              [
                                _c("span", [_vm._v(_vm._s(item.alias))]),
                                _c("span", [
                                  _vm._v(
                                    "【" + _vm._s(item.aliasTypeDec) + "】"
                                  ),
                                ]),
                                _c("code-icon", {
                                  staticStyle: {
                                    width: "24px",
                                    height: "16px",
                                    "margin-left": "5px",
                                  },
                                  attrs: {
                                    iconCode: item.aliasLangueIcon,
                                    size: 24,
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[1].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [
                      _c("span", [
                        _vm._v("药品"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("成分"),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "substanceTable content",
                      staticStyle: { width: "1000px" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.dkbDpSpecIngredientList,
                            border: "",
                            stripes: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                            "span-method": _vm.objectSpanMethod,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "drugProdName",
                              label: "药物名",
                              width: "200",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goToLink(
                                              scope.row,
                                              "drug"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.drugName))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "substanceName",
                              label: "成分物质",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goToLink(
                                              scope.row,
                                              "substance"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.substanceName))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "substanceName",
                              label: "成分量",
                              width: "100",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.substanceQtyMax) +
                                        " " +
                                        _vm._s(scope.row.substanceQtyUnitDesc) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ingredientTypeDesc",
                              label: "成分类型",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[2].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure bacg-el-row" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("药物组")])]
                  ),
                  _vm.dkbDrugGroupMappingList.length > 0
                    ? _c(
                        "el-col",
                        { staticClass: "content bacg clearfix" },
                        _vm._l(_vm.dkbDrugGroupMappingList, function (item) {
                          return _c(
                            "li",
                            {
                              key: item.drugGroupId,
                              staticClass: "substanceLi background-li",
                            },
                            [_vm._v(" " + _vm._s(item.drugGroupName) + " ")]
                          )
                        }),
                        0
                      )
                    : _c("el-col", { staticClass: "content bacg clearfix" }, [
                        _c(
                          "li",
                          {
                            staticClass: "substanceLi background-li no-data-li",
                          },
                          [_vm._v("暂无数据")]
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[3].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure bacg-el-row" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("制剂属性")])]
                  ),
                  _c("el-col", { staticClass: "content bacg clearfix" }, [
                    _vm.isShowPropertyCommon(_vm.propertyObj.typeDesc)
                      ? _c("li", { staticClass: "substanceLi background-li" }, [
                          _c("span", [_vm._v("剂型：")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.propertyObj.typeDesc)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.isShowPropertyCommon(_vm.propertyObj.routeStr)
                      ? _c("li", { staticClass: "substanceLi background-li" }, [
                          _c("span", [_vm._v("给药途径：")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.propertyObj.routeStr)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.isShowPropertyCommon(_vm.propertyObj.siteStr)
                      ? _c("li", { staticClass: "substanceLi background-li" }, [
                          _c("span", [_vm._v("给药部位：")]),
                          _c("span", [_vm._v(_vm._s(_vm.propertyObj.siteStr))]),
                        ])
                      : _vm._e(),
                    _vm.isShowPropertyCommon(_vm.propertyObj.drugTypeSpecsBean)
                      ? _c("li", { staticClass: "substanceLi background-li" }, [
                          _c("span", [_vm._v("中药类别与规格：")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.propertyObj.drugTypeSpecsBean)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.isShowPropertyCommon(_vm.propertyObj.drugProcMethodBean)
                      ? _c("li", { staticClass: "substanceLi background-li" }, [
                          _c("span", [_vm._v("中药炮制方法：")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.propertyObj.drugProcMethodBean)),
                          ]),
                        ])
                      : _vm._e(),
                    !_vm.isShowProperty
                      ? _c(
                          "li",
                          {
                            staticClass: "substanceLi background-li no-data-li",
                          },
                          [_vm._v("暂无数据")]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[4].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("药品企业")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "substanceTable content",
                      staticStyle: { width: "50%" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "drugTable",
                          attrs: {
                            data: _vm.manufacturerTableData,
                            border: "",
                            stripes: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "企业名称" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", {
                                      staticStyle: {
                                        "white-space": "pre-line",
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(scope.row.name),
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "type",
                              label: "企业类型",
                              width: "180",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[5].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("药品标识")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "substanceTable content",
                      staticStyle: { width: "50%" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "drugTable",
                          attrs: {
                            data: _vm.approvalTableData,
                            border: "",
                            stripes: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "type",
                              label: "标识类型",
                              width: "180",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "value", label: "标识值" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[6].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("药品规格包装")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "substanceTable content",
                      staticStyle: { width: "80%" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "drugTable",
                          attrs: {
                            data: _vm.dkbPdProdList,
                            border: "",
                            stripes: "",
                            "header-cell-style": { "text-align": "center" },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "packTypeDesc",
                              label: "包装类型",
                              align: "center",
                              width: "70",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "packSpecDesc",
                              label: "规格包装",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.showSpec
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.showSpec) +
                                              " * "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.packQty) +
                                        " " +
                                        _vm._s(scope.row.specUnitDesc) +
                                        " "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "18px",
                                          "font-weight": "bold",
                                        },
                                      },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.packQtyUnitDesc) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "stateMedicareNum",
                              label: "国家医保代码",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.pdProdMatList,
                                    function (prodMat, index) {
                                      return _c("div", { key: prodMat.id }, [
                                        prodMat.stateMedicareNum
                                          ? _c("p", [
                                              _c("span", [
                                                _vm._v(
                                                  "[" + _vm._s(index + 1) + "]"
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    prodMat.stateMedicareNum
                                                  )
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "shMedicareNum",
                              label: "上海统编码",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.pdProdMatList,
                                    function (prodMat, index) {
                                      return _c("div", { key: prodMat.id }, [
                                        prodMat.shMedicareNum
                                          ? _c("p", [
                                              _c("span", [
                                                _vm._v(
                                                  "[" + _vm._s(index + 1) + "]"
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(prodMat.shMedicareNum)
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "有效期",
                              prop: "indateUnitDesc",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.pdProdMatList,
                                    function (prodMat, index) {
                                      return _c("div", { key: prodMat.id }, [
                                        prodMat.indate
                                          ? _c("p", [
                                              _c("span", [
                                                _vm._v(
                                                  "[" + _vm._s(index + 1) + "]"
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(prodMat.indate) +
                                                    " "
                                                ),
                                                prodMat.indateUnitDesc === "月"
                                                  ? _c("span", [_vm._v("个")])
                                                  : _vm._e(),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      prodMat.indateUnitDesc
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "storage",
                              label: "储藏条件",
                              width: "160",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.pdProdMatList,
                                    function (prodMat, index) {
                                      return _c("div", { key: prodMat.id }, [
                                        prodMat.storage
                                          ? _c("p", [
                                              _c("span", [
                                                _vm._v(
                                                  "[" + _vm._s(index + 1) + "]"
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(_vm._s(prodMat.storage)),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "barcode69", label: "69码" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.pdProdMatList,
                                    function (prodMat, index) {
                                      return _c("div", { key: prodMat.id }, [
                                        prodMat.barcode69
                                          ? _c("p", [
                                              _c("span", [
                                                _vm._v(
                                                  "[" + _vm._s(index + 1) + "]"
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(prodMat.barcode69)
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[7].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure bacg-el-row" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("药品分类")])]
                  ),
                  _vm.classifyList.length > 0
                    ? _c(
                        "el-col",
                        {
                          staticClass: "clearfix",
                          staticStyle: { "margin-left": "20px" },
                        },
                        _vm._l(_vm.classifyList, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              ref: "classify-li" + index,
                              refInFor: true,
                              staticClass: "fl classify-li",
                            },
                            [
                              _c("el-tree", {
                                staticClass: "classify-tree",
                                attrs: {
                                  data: item,
                                  props: _vm.defaultProps,
                                  "empty-text": "暂无数据",
                                  "node-key": "id",
                                  "expand-on-click-node": false,
                                  "default-expanded-keys": _vm.classifyArr,
                                  "default-expand-all": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var node = ref.node
                                        var data = ref.data
                                        return _c(
                                          "span",
                                          { staticClass: "custom-tree-node" },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(node.label)),
                                            ]),
                                          ]
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c("el-col", { staticClass: "content bacg clearfix" }, [
                        _c(
                          "li",
                          {
                            staticClass: "substanceLi background-li no-data-li",
                          },
                          [_vm._v("暂无数据")]
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[8].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [
                      _c("span", [
                        _vm._v("同制剂规格"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("药品"),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "substanceTable content",
                      staticStyle: { width: "80%" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "similarTable",
                          attrs: {
                            data: _vm.sameTableData,
                            border: "",
                            stripes: "",
                            "header-cell-style": {
                              "text-align": "left",
                              "padding-left": "8px",
                            },
                            "cell-style": { "text-align": "left" },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "drugName", label: "名称" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goToLink(
                                              scope.row,
                                              "drugProd"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(scope.row.drugName) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "substanceName", label: "成分" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.dkbDpSpecIngredientList,
                                    function (ingredient, index) {
                                      return _c(
                                        "span",
                                        { key: ingredient.id },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "link",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goToLink(
                                                    ingredient,
                                                    "substance"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    ingredient.substanceName
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "unit-link" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f(
                                                        "relatedDrugsIngredient"
                                                      )(ingredient)
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          index <
                                          scope.row.dkbDpSpecIngredientList
                                            .length -
                                            1
                                            ? _c("span", [_vm._v("+")])
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "preparationForm",
                              label: "剂型",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.dkbPpInfo.preparationForm
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "preparationSpecDesc",
                              label: "规格包装",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.dkbPdProdList,
                                    function (prod, index) {
                                      return _c("span", { key: prod.id }, [
                                        scope.row.dkbPpInfo.showSpec
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.dkbPpInfo.showSpec
                                                  ) +
                                                  " * "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          " " +
                                            _vm._s(prod.packQty) +
                                            " / " +
                                            _vm._s(prod.packQtyUnitDesc) +
                                            " "
                                        ),
                                        index <
                                        scope.row.dkbPdProdList.length - 1
                                          ? _c("span")
                                          : _vm._e(),
                                        _c("br"),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "生产厂商", width: "200" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", {
                                      staticStyle: {
                                        "white-space": "pre-line",
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          scope.row.manufacturerStr
                                        ),
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }