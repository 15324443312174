<template>
  <div class="drugProd-wrap" style="padding: 1rem;">
    <div style="background: #FFFFFF;padding-left: 20px;margin-bottom: 10px;">
      <el-tabs v-if="reload" v-model="tabIndex" @tab-click="jump">
        <el-tab-pane :label="tabs[0].title" v-if="tabs[0].isShow" name="0"></el-tab-pane>
        <el-tab-pane :label="tabs[1].title" v-if="tabs[1].isShow" name="1"></el-tab-pane>
        <el-tab-pane :label="tabs[2].title" v-if="tabs[2].isShow" name="2"></el-tab-pane>
        <el-tab-pane :label="tabs[3].title" v-if="tabs[3].isShow" name="3"></el-tab-pane>
        <el-tab-pane :label="tabs[4].title" v-if="tabs[4].isShow" name="4"></el-tab-pane>
        <el-tab-pane :label="tabs[5].title" v-if="tabs[5].isShow" name="5"></el-tab-pane>
        <el-tab-pane :label="tabs[6].title" v-if="tabs[6].isShow" name="6"></el-tab-pane>
        <el-tab-pane :label="tabs[7].title" v-if="tabs[7].isShow" name="7"></el-tab-pane>
        <el-tab-pane :label="tabs[8].title" v-if="tabs[8].isShow" name="8"></el-tab-pane>
      </el-tabs>
    </div>
    <div ref="scrollContent" class="scroll-content recordContent" @scroll="onScroll" :style="'overflow-x: hidden; overflow-y: auto;height:' + contentStyleObj.height">
      <el-row class="substanceName">
        <el-col :span="24" style="padding-left:15px;">
          <!-- <i class="el-icon-info" title="点击查看来源引用"></i> -->
          <span style="margin-right: 5px;">{{ drugBasicObj.defaultName }}</span>
          <span style="margin-right: 5px;">(id: {{ drugProdId }})</span>
          <code-icon :iconCode="classifyObj.classifyIcon" :size="26"/>
          <code-icon
            v-for="(item, index) in classifyIcons"
            :key="index"
            :iconCode="item"
            :size="26"
            style="margin-left: 5px;" />
          <!-- <i class="el-icon-edit" @click=""></i> -->
          <el-tooltip
            v-if="Number(drugProdId) === 8024"
            content="用药指导"
            placement="top"
            effect="light"
            class="fr">
            <el-button class="none-hightlight" style="border: none;padding: 0;margin: 8px 20px 0 0;" @click="toDrugUseInstruction()">
              <svg-icon icon-class="drug-icon" style="width: 22px;height: 22px;" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-if="Number(drugProdId) === 8024"
            content="药品说明书"
            placement="top"
            effect="light"
            class="fr">
            <el-button class="none-hightlight" style="border: none;padding: 0;margin: 10px 15px 0;">
              <!-- <a
                href="http://172.16.0.109:8500/files/19098.pdf"
                target="_blank">
                <svg-icon icon-class="drug-instructions" style="width: 26px;height: 20px;" />
              </a> -->
              <a
                href="/inlyta.html"
                target="_blank">
                <svg-icon icon-class="drug-instructions" style="width: 26px;height: 20px;" />
              </a>
            </el-button>
          </el-tooltip>
          <!-- <el-tooltip content="药品说明书" placement="bottom" effect="light">
            <a
            v-if="Number(drugProdId) === 8024"
            href="http://www.pdbox.cn/files/19098.pdf"
            target="_blank"
            class="fr">
            55
            <svg-icon icon-class="drug-instructions" style="width: 26px;height: 20px;" />
          </a>
          </el-tooltip> -->
          <!-- <a
            v-if="Number(drugProdId) === 8024"
            href="http://www.pdbox.cn/files/19098.pdf"
            target="_blank"
            class="fr">
            <svg-icon icon-class="drug-instructions" style="width: 26px;height: 20px;" />
          </a> -->
          <!-- <span v-show="classifyObj.classifyDesc" style="font-size: 12px;color: #2D5AFA;border-radius: 50%;border: 1px solid #2D5AFA;padding: 2px 4px;">{{ classifyObj.classifyDesc | classifyDesc }}</span> -->
        </el-col>
      </el-row>
      <!-- 药品名称 -->
      <div :ref="tabs[0].refName" class="scroll-item">
        <el-row class="substanceStructure bacg-el-row">
          <el-col :span="24" class="substanceTitle">
            <span>药品名称</span>
          </el-col>
          <el-col class="content bacg">
            <!-- <ul>
              <li
                v-for="item in dkbDpAliasList"
                :key="item.id">
                <span>{{ item.aliasTypeDec }}:</span>
                <span>{{ item.alias }}</span>
              </li>
            </ul> -->
            <el-form ref="form" :model="form" label-width="0px">
              <!-- <el-form-item
                v-for="item in dkbDpAliasList"
                :key="item.id"
                :label="item.aliasTypeDec + ':'"> -->
              <el-form-item
                v-for="item in dkbDpAliasList"
                :key="item.id">
                <li class="background-li el-form-item-li">
                  <span>{{ item.alias }}</span>
                  <span>【{{ item.aliasTypeDec }}】</span>
                  <code-icon :iconCode="item.aliasLangueIcon" :size="24" style="width: 24px;height: 16px;margin-left: 5px;"/>
                  <!-- <svg-icon v-if="item.languageId === 100001" icon-class="china" style="width: 24px;height: 16px;margin-left: 5px;"/>
                  <svg-icon v-else icon-class="yingguoguoqi" style="width: 24px;height: 16px;margin-left: 5px;"/> -->
                </li>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <!-- 药品成分 -->
      <div :ref="tabs[1].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>药品<span style="color: red;">成分</span></span>
          </el-col>
          <el-col class="substanceTable content" style="width: 1000px;">
            <el-table
              :data="dkbDpSpecIngredientList"
              border
              stripes
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :span-method="objectSpanMethod">
              <el-table-column
                prop="drugProdName"
                label="药物名"
                width="200">
                <template slot-scope="scope">
                  <span class="link" @click="goToLink(scope.row, 'drug')">{{ scope.row.drugName }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="substanceName"
                label="成分物质"
                width="180">
                <template slot-scope="scope">
                  <span class="link" @click="goToLink(scope.row, 'substance')">{{ scope.row.substanceName }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="substanceName"
                label="成分量"
                width="100">
                <template slot-scope="scope">
                  {{ scope.row.substanceQtyMax }}
                  {{ scope.row.substanceQtyUnitDesc }}
                </template>
              </el-table-column>
              <el-table-column
                prop="ingredientTypeDesc"
                label="成分类型">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!-- 药物组 -->
      <div :ref="tabs[2].refName" class="scroll-item">
        <el-row class="substanceStructure bacg-el-row">
          <el-col :span="24" class="substanceTitle">
            <span>药物组</span>
          </el-col>
          <el-col v-if="dkbDrugGroupMappingList.length > 0" class="content bacg clearfix">
            <li
              v-for="item in dkbDrugGroupMappingList"
              :key="item.drugGroupId"
              class="substanceLi background-li">
              {{ item.drugGroupName }}
            </li>
          </el-col>
          <el-col v-else class="content bacg clearfix">
            <li class="substanceLi background-li no-data-li">暂无数据</li>
          </el-col>
        </el-row>
      </div>
      <!-- 制剂属性 -->
      <div :ref="tabs[3].refName" class="scroll-item">
        <el-row class="substanceStructure bacg-el-row">
          <el-col :span="24" class="substanceTitle">
            <span>制剂属性</span>
          </el-col>
          <el-col class="content bacg clearfix">
            <li v-if="isShowPropertyCommon(propertyObj.typeDesc)" class="substanceLi background-li">
              <span>剂型：</span>
              <span>{{ propertyObj.typeDesc }}</span>
            </li>
            <li v-if="isShowPropertyCommon(propertyObj.routeStr)" class="substanceLi background-li">
              <span>给药途径：</span>
              <span>{{ propertyObj.routeStr }}</span>
            </li>
            <li v-if="isShowPropertyCommon(propertyObj.siteStr) " class="substanceLi background-li">
              <span>给药部位：</span>
              <span>{{ propertyObj.siteStr }}</span>
            </li>
            <li v-if="isShowPropertyCommon(propertyObj.drugTypeSpecsBean)" class="substanceLi background-li">
              <span>中药类别与规格：</span>
              <span>{{ propertyObj.drugTypeSpecsBean }}</span>
            </li>
            <li v-if="isShowPropertyCommon(propertyObj.drugProcMethodBean)" class="substanceLi background-li">
              <span>中药炮制方法：</span>
              <span>{{ propertyObj.drugProcMethodBean }}</span>
            </li>
            <li v-if="!isShowProperty" class="substanceLi background-li no-data-li">暂无数据</li>
          </el-col>
        </el-row>
      </div>
      <!-- 药品企业 -->
      <div :ref="tabs[4].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>药品企业</span>
          </el-col>
          <el-col class="substanceTable content" style="width: 50%">
            <el-table
              :data="manufacturerTableData"
              border
              stripes
              ref="drugTable"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}">
              <el-table-column
                label="企业名称">
                <template slot-scope="scope">
                  <div v-html="scope.row.name" style="white-space: pre-line;"></div>
                </template>
              </el-table-column>
              <el-table-column
                prop="type"
                label="企业类型"
                width="180">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!--药品标识-->
      <div :ref="tabs[5].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>药品标识</span>
          </el-col>
          <el-col class="substanceTable content" style="width: 50%">
            <el-table
              :data="approvalTableData"
              border
              stripes
              ref="drugTable"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}">
              <el-table-column
                prop="type"
                label="标识类型"
                width="180">
              </el-table-column>
              <el-table-column
                prop="value"
                label="标识值">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!--药品规格包装-->
      <div :ref="tabs[6].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>药品规格包装</span>
          </el-col>
          <el-col class="substanceTable content" style="width: 80%">
            <el-table
              :data="dkbPdProdList"
              border
              stripes
              ref="drugTable"
              :header-cell-style="{'text-align':'center'}">
              <el-table-column
                prop="packTypeDesc"
                label="包装类型"
                align="center"
                width="70">
              </el-table-column>
              <el-table-column
                prop="packSpecDesc"
                label="规格包装"
                width="180">
                <template slot-scope="scope">
                  <span v-if="scope.row.showSpec">
                    {{ scope.row.showSpec }}
                    *
                  </span>
                  {{ scope.row.packQty }}
                  {{ scope.row.specUnitDesc }}
                  <span style="font-size: 18px;font-weight: bold;">/</span>
                  {{ scope.row.packQtyUnitDesc }}
                </template>
              </el-table-column>
              <el-table-column
                prop="stateMedicareNum"
                label="国家医保代码">
                <template slot-scope="scope">
                  <div
                    v-for="(prodMat, index) in scope.row.pdProdMatList"
                    :key="prodMat.id">
                    <p v-if="prodMat.stateMedicareNum">
                      <span>[{{ index + 1 }}]</span>
                      <span>{{ prodMat.stateMedicareNum }}</span>
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="shMedicareNum"
                label="上海统编码">
                <template slot-scope="scope">
                  <div
                    v-for="(prodMat, index) in scope.row.pdProdMatList"
                    :key="prodMat.id">
                    <p v-if="prodMat.shMedicareNum">
                      <span>[{{ index + 1 }}]</span>
                      <span>{{ prodMat.shMedicareNum }}</span>
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="有效期"
                prop="indateUnitDesc"
                width="120">
                <template slot-scope="scope">
                  <div
                    v-for="(prodMat, index) in scope.row.pdProdMatList"
                    :key="prodMat.id">
                    <p v-if="prodMat.indate">
                      <span>[{{ index + 1 }}]</span>
                      <span>
                        {{ prodMat.indate }}
                        <span v-if="prodMat.indateUnitDesc === '月'">个</span>
                        {{ prodMat.indateUnitDesc }}
                      </span>
                    </p>
                  </div>
                </template>
                <!-- <template slot-scope="scope">
                  {{ scope.row.indate }}
                  {{ scope.row.indateUnitDesc }}
                </template> -->
              </el-table-column>
              <el-table-column
                prop="storage"
                label="储藏条件"
                width="160">
                <template slot-scope="scope">
                  <div
                    v-for="(prodMat, index) in scope.row.pdProdMatList"
                    :key="prodMat.id">
                    <p v-if="prodMat.storage">
                      <span>[{{ index + 1 }}]</span>
                      <span>{{ prodMat.storage }}</span>
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="barcode69"
                label="69码">
                <template slot-scope="scope">
                  <div
                    v-for="(prodMat, index) in scope.row.pdProdMatList"
                    :key="prodMat.id">
                    <p v-if="prodMat.barcode69">
                      <span>[{{ index + 1 }}]</span>
                      <span>{{ prodMat.barcode69 }}</span>
                    </p>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!-- 药品分类 -->
      <div :ref="tabs[7].refName" class="scroll-item">
        <el-row class="substanceStructure bacg-el-row">
          <el-col :span="24" class="substanceTitle">
            <span>药品分类</span>
          </el-col>
          <!-- <el-select class="form-input" v-model="node.name" filterable style="width: 200px;">
            <el-option style="height: auto;" :value="node">
              <el-tree
                :data="classifyList"
                default-expand-all
                show-checkbox
                check-strictly
                ref="treeForm"
                node-key="id"
                :expand-on-click-node="false"
                :props="props"
                @check="handleNodeCheck"
                @check-change="handleClick"
                @node-click="handleNodeClick">
              </el-tree>
            </el-option>
          </el-select> -->
          <!-- <treeselect style="width: 300px;float: left"
            :options="classifyList"
            :normalizer="normalizerTreeNode"
            :disable-branch-nodes="true"
            :show-count="true" /> -->
            <!-- <el-col :span="24">
              <div
                v-for="(item, index) in classifyList"
                :key="index"
                style="display:inline-block;margin: 0 0px 20px 20px;">
                <treeselect style="width: 200px;"
                  :value="item[0].valueId"
                  :options="item"
                  :normalizer="normalizerTreeNode"
                  :disable-branch-nodes="true"
                  :show-count="true" />
              </div>
            </el-col> -->
            <!-- <el-row :gutter="10" class="content" style="padding-left: 30px;">
              <el-col
                v-for="(item, index) in classifyList"
                :key="index"
                :xs="8" :sm="6" :md="6" :lg="6" :xl="6">
              <el-tree
                style="border: 1px solid #638BF7;border-radius: 15px;"
                :data="item"
                :props="defaultProps"
                highlight-current
                default-expand-all></el-tree>
            </el-col>
          </el-row> -->
          <el-col v-if="classifyList.length > 0" class="clearfix" style="margin-left: 20px;">
            <li class="fl classify-li"
              v-for="(item, index) in classifyList"
              :key="index"
              :ref="'classify-li' + index">
            <el-tree
              :data="item"
              :props="defaultProps"
              empty-text="暂无数据"
              node-key="id"
              class="classify-tree"
              :expand-on-click-node="false"
              :default-expanded-keys="classifyArr"
              default-expand-all>
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <!-- <code-icon v-if="data.icon" :iconCode="data.icon" :size="16" style="width: 16px;height: 16px;margin-left: 5px;"/> -->
              </span>
            </el-tree>
            </li>
          </el-col>
          <el-col v-else class="content bacg clearfix">
            <li class="substanceLi background-li no-data-li">暂无数据</li>
          </el-col>
        </el-row>
        <!-- <el-row style="background-color: #fff;">
          <el-col
            v-for="(item, index) in classifyList"
            :key="index"
            :xs="8" :sm="6" :md="4" :lg="4" :xl="6"
            style="border: 1px solid #638BF7;border-radius: 15px;margin-right: 10px;">
            <el-tree :data="item" :props="defaultProps" default-expand-all></el-tree>
          </el-col>
        </el-row> -->
      </div>
      <!-- 同制剂规格药品 -->
      <div :ref="tabs[8].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>同制剂规格<span style="color: red;">药品</span></span>
          </el-col>
          <el-col class="substanceTable content" style="width: 80%">
            <el-table
              :data="sameTableData"
              border
              stripes
              ref="similarTable"
              :header-cell-style="{'text-align':'left', 'padding-left': '8px'}"
              :cell-style="{'text-align':'left'}">
              <el-table-column
                prop="drugName"
                label="名称">
                <template slot-scope="scope">
                  <span class="link" @click="goToLink(scope.row, 'drugProd')">
                    {{ scope.row.drugName }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="substanceName"
                label="成分">
                <template slot-scope="scope">
                  <!-- <span style="margin-left: 10px">{{ scope.row.ingredientStr }}</span> -->
                  <span
                    v-for="(ingredient, index) in scope.row.dkbDpSpecIngredientList"
                    :key="ingredient.id">
                    <span class="link" @click="goToLink(ingredient, 'substance')">
                      {{ ingredient.substanceName }}
                      <span class="unit-link">{{ ingredient | relatedDrugsIngredient }}</span>
                      <!-- <span class="unit-link">（{{ ingredient.substanceQtyMax }}</span>
                      <span class="unit-link">{{ ingredient.substanceQtyUnitDesc }}）</span> -->
                    </span>
                    <span v-if="index < scope.row.dkbDpSpecIngredientList.length - 1">+</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="preparationForm"
                label="剂型"
                width="180">
                <template slot-scope="scope">
                  <span>{{ scope.row.dkbPpInfo.preparationForm }}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="routeName"
                label="用药途径"
                width="180">
                <template slot-scope="scope">
                  <span>{{ scope.row.dkbPpInfo.routeName }}</span>
                </template>
              </el-table-column> -->
              <el-table-column
                prop="preparationSpecDesc"
                label="规格包装"
                width="180">
                <template slot-scope="scope">
                  <span v-for="(prod, index) in scope.row.dkbPdProdList" :key="prod.id">
                    <span v-if="scope.row.dkbPpInfo.showSpec">
                      {{ scope.row.dkbPpInfo.showSpec }}
                      *
                    </span>
                    {{ prod.packQty }} / {{ prod.packQtyUnitDesc }}
                    <!-- <span v-if="index < scope.row.dkbPdProdList.length - 1">,</span> -->
                    <span v-if="index < scope.row.dkbPdProdList.length - 1"></span><br/>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="生产厂商"
                width="200">
                <template slot-scope="scope">
                  <div v-html="scope.row.manufacturerStr" style="white-space: pre-line;"></div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import $request from '@/utils/request'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  getBasicInfoApi,
  getDrugInfoApi,
  getPropertyApi,
  getClassifyApi,
  getDrugDataApi
} from '@/api/dkm/drugProd/view.js'
export default {
  name: 'DrugProdView',
  components: { Treeselect },
  filters: {
    classifyDesc(str) {
      return str.substring(0, 1)
    },
    // 成分
    relatedDrugsIngredient(ingredient) {
      if (ingredient.substanceQtyMax || ingredient.substanceQtyUnitDesc) {
        return '(' + ingredient.substanceQtyMax + ingredient.substanceQtyUnitDesc + ')'
      }
    },
  },
  data() {
    return {
      tabs: [
        {
          isShow: true,
          title: '药品名称',
          refName: 'setOneRef'
        },
        {
          isShow: true,
          title: '药品成分',
          refName: 'setTwoRef'
        },
        {
          isShow: true,
          title: '药物组',
          refName: 'setThreeRef'
        },
        {
          isShow: true,
          title: '制剂属性',
          refName: 'setFourRef'
        },
        {
          isShow: true,
          title: '药品企业',
          refName: 'setFiveRef'
        },
        {
          isShow: true,
          title: '药品标识',
          refName: 'setSixRef'
        },
        {
          isShow: true,
          title: '药品规格包装',
          refName: 'setSevenRef'
        },
        {
          isShow: true,
          title: '药品分类',
          refName: 'setEightRef'
        },
        {
          isShow: true,
          title: '同制剂规格药品',
          refName: 'setNineRef'
        }
      ],
      drugProdId: null, // 制剂ID
      drugBasicObj: {
        defaultName: '',
        languageId: '',
        dkbDpAliasList: [], // 关联的药品别名
        manufacturerName: '', // 生产企业名称
        mahName: '', // 上市许可持有人名称
        standardCode: '', // 本位码
        dkbDpApprovalNumList: [], // 关联的药品批准文号
      },
      dkbDpAliasList: [], // 别名数组
      manufacturerTableData: [
        {
          name: '',
          type: '生产企业'
        },
        {
          name: '',
          type: '上市许可持有人'
        }
      ],
      approvalTableData: [], // 药品标识表格
      dkbDpSpecIngredientList: [], // 药品成分
      dkbPdProdList: [], // 药品规格包装
      classifyObj: {
        classifyIcon: '',
        drugName: '', // 药物名称
        classifyDesc: '',
        classify: '' // 中西草标识描述
      },
      form: {

      },
      // 制剂属性
      propertyObj: {
        typeDesc: '', // 剂型名称
        ppRouteBeanList: [], // 给药途径 routeName
        ppSiteBeanList: [], // 给药部位
        routeStr: '',
        siteStr: '',
        drugProcMethodBean: '', // 中药炮制方法
        drugTypeSpecsBean: '' // 中药类别与规格
      },
      // 制剂规格
      ppSpecBeanList: [], // 制剂规格列表 showSpec
      value: '',
      reload: true,
      tabIndex: '0',
      contentStyleObj: {
        height: '100px'
      },
      // activeName: '1',
      medicalCompoundsTableData: [], // 药物成分
      dkbDrugGroupMappingList: [], // 药物组
      props: {
        children: 'children',
        label: 'name',
        value: 'id'
      },
      classifyList: [],
      classifyIcons: [], // 分类图标
      node: {}, //选中的记录
      drugTableData: [], // 相关药品（属于该制剂的药品）
      drugObj: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        drugProdId: ''
      },
      sameTableData: [], // 同制剂规格药品
      // 同类药物制剂
      similarTableData: [],
      similarObj: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        drugProdId: ''
      },
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      classifyArr: [],
      drugId: '', // 药物id
      scrollY: 0
    }
  },
  computed: {
    isShowProperty() {
      // if (this.propertyObj.typeDesc === '' && this.propertyObj.routeStr === '' && this.propertyObj.siteStr === '') {
      //   return false
      // } else {
      //   return true
      // }
      let typeDescFlag = false
      let routeStrFlag = false
      let siteStrFlag = false
      let drugTypeSpecsBeanFlag = false
      let drugProcMethodBeanFlag = false
      if (this.propertyObj.typeDesc !== '' && this.propertyObj.typeDesc) {
        typeDescFlag = true
      } else {
        typeDescFlag = false
      }
      if (this.propertyObj.routeStr !== '' && this.propertyObj.routeStr) {
        routeStrFlag = true
      } else {
        routeStrFlag = false
      }
      if (this.propertyObj.siteStr !== '' && this.propertyObj.siteStr) {
        siteStrFlag = true
      } else {
        siteStrFlag = false
      }
      if (this.propertyObj.drugTypeSpecsBean !== '' && this.propertyObj.drugTypeSpecsBean) {
        drugTypeSpecsBeanFlag = true
      } else {
        drugTypeSpecsBeanFlag = false
      }
      if (this.propertyObj.drugProcMethodBean !== '' && this.propertyObj.drugProcMethodBean) {
        drugProcMethodBeanFlag = true
      } else {
        drugProcMethodBeanFlag = false
      }
      // 三个都为false，则return false
      if (typeDescFlag || routeStrFlag || siteStrFlag || drugTypeSpecsBeanFlag || drugProcMethodBeanFlag) {
        return true
      } else {
        return false
      }
    },
    isShowPropertyCommon() {
      return function (property) {
        if (property !== '' && property) {
          return true
        } else {
          return false
        }
      }
    }
  },
  async activated() {
    this.classifyIcons = []
    this.drugProdId = this.$route.params.id
    this.drugObj.drugProdId = this.drugProdId
    this.similarObj.drugProdId = this.drugProdId
    this.getHight()
    window.addEventListener('resize', this.getHight)
    await this.fetchData()
    this.$nextTick(() => {
      document.querySelector('.recordContent').scrollTop = this.scrollY
      for (let i = 0; i < this.classifyList.length; i++) {
        let width = this.$refs['classify-li' + i][0].offsetWidth
        this.$refs['classify-li' + i][0].style.width = width + 'px'
      }
    })
  },
  async mounted() {
    let $this = this
    this.$nextTick(() => {
      let dom = this.$refs.similarTable.bodyWrapper
      dom.addEventListener("scroll", async function() {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight
        // console.log(scrollDistance)
        if(scrollDistance <= 10) { //等于0证明已经到底，可以请求接口
          // console.log($this.similarObj.pageNum)
          if($this.similarObj.pageNum < $this.similarObj.total){//当前页数小于总页数就请求
            $this.similarObj.pageNum++ //当前页数自增
            //请求接口的代码
            await $this.fetchSimilarData(true)
          }
        }
      })
    })
  },
  beforeRouteEnter (to, from, next) {
    if (to.name === 'DrugProdView') {
      next(vm => {
        const scrollContent = vm.$refs.scrollContent
        // 记录滚动高度
        scrollContent.scrollTop = vm.$route.meta.scroll || 0
        vm.scrollY = vm.$route.meta.scroll
      })
    } else {
      next(vm => {
        const scrollContent = vm.$refs.scrollContent
        // 不记录滚动高度
        scrollContent.scrollTop = 0
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (from.name === 'DrugProdView') {
      const scrollContent = this.$refs.scrollContent
      this.$route.meta.scroll = scrollContent.scrollTop
    }
    next()
  },
  methods: {
    async fetchData() {
      await this.fetchGroupData()
      this.propertyObj.siteStr = ''
      this.propertyObj.routeStr = ''
      let siteArr = []
      let routeArr = []
      this.approvalTableData = []
      let manufacturerArr = []
      let res = await getBasicInfoApi(this.drugProdId)
      if (res.code === 200) {
        this.drugBasicObj.dkbDpAliasList = res.data && res.data.dkbDpAliasList
        this.dkbDpAliasList = res.data && res.data.dkbDpAliasList
        let aliasItem = this.dkbDpAliasList.find(item => item.isPrimary === '1')
        this.drugBasicObj.defaultName = aliasItem && aliasItem.alias
        this.drugBasicObj.manufacturerName = res.data && res.data.manufacturerName
        this.drugBasicObj.mahName = res.data && res.data.standardCode
        this.drugBasicObj.standardCode = res.data && res.data.mahName
        this.drugBasicObj.dkbDpApprovalNumList = res.data && res.data.dkbDpApprovalNumList
        let dkbDpSpecIngredientList = res.data && res.data.dkbDpSpecIngredientList // 药品成分
        // this.manufacturerTableData[0].name = res.data && res.data.manufacturerName
        res.data.dkbDpManufacturerList.forEach(manufacture => {
          manufacturerArr.push(manufacture.manufacturerName)
        })
        this.manufacturerTableData[0].name = manufacturerArr.join('\n')
        this.manufacturerTableData[1].name = res.data && res.data.mahName
        /* this.approvalTableData[0] = {
          type: '本位码',
          value: res.data && res.data.standardCode
        } */
        
        res.data && res.data.dkbDpStandardCodeList.forEach(item => {
          this.approvalTableData.push({
            type: '本位码',
            value: item.standardCode
          })
        })
        res.data && res.data.dkbDpApprovalNumList.forEach(item => {
          this.approvalTableData.push({
            type: '批准文号',
            value: item.approvalNum
          })
        })
        // console.log(this.approvalTableData)
        this.dkbPdProdList = res.data && res.data.dkbPdProdList // 药品规格包装
        this.dkbPdProdList.forEach(item => {
          item.showSpec = res.data && res.data.dkbPpInfo.showSpec
        })
        // 药品成分数据处理
        dkbDpSpecIngredientList.forEach(item => {
          // item.drugName = this.drugBasicObj.defaultName
          item.drugName = this.classifyObj.drugName
        })
        this.dkbDpSpecIngredientList = dkbDpSpecIngredientList
      }
      await this.fetchPropertyData()
      await this.fetchClassifyData()
      await this.fetchDrugData()
      console.log(this.classifyIcons)
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: this.dkbDpSpecIngredientList.length,
            colspan: 1
          }
        } else {
          // 一定要将除了第一行第一列之外的 第一列其他行删除
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    },
    // 药物组
    async fetchGroupData() {
      const obj = {
        drugProdId: this.drugProdId
      }
      let res = await getDrugInfoApi(obj)
      if (res.code === 200) {
        this.classifyObj.classifyDesc = res.data && res.data.classifyDesc
        this.classifyObj.classifyIcon = res.data && res.data.classifyIcon
        this.dkbDrugGroupMappingList = (res.data && res.data.dkbDrugGroupMappingList) || []
        this.drugId = res.data.id
        let drugItem = res.data.dkbDrugAliasList.find(item => item.isPrimary === '1')
        this.classifyObj.drugName = drugItem && drugItem.alias
      }
    },
    // 药品分类
    async fetchClassifyData() {
      const obj = {
        dataId: this.drugProdId,
        dataType: 6
      }
      let classifyRes = await getClassifyApi(obj)
      if (classifyRes.code === 200) {
        let classifyList = classifyRes.data || []
        let arr = this.classifyDataHandle(classifyList)
        this.classifyList = arr
        this.classifyIcons = [...new Set(this.classifyIcons)]
      }
      this.classifyList.forEach(item => {
        item.forEach(i => {
          this.classifyArr.push(i.id)
        })
      })
    },
    // 处理分类的数据
    classifyDataHandle(classifyList) {
      let arr = []
      for (let item of classifyList) {
        // if (item.children && item.children.length > 0) {
        //   this.classifyDataHandle(item.children)
        // }
        if (!item.children) {
          item.children = []
          item.label = item.name
          item.value = item.id
          item.valueId = item.id
          // if (item.icon) {
          //   this.classifyIcons.push(item.icon)
          // }
          item.icon && this.classifyIcons.push(item.icon)
        } else if (item.children && item.children.length > 0) {
          // item.label = item.children[0].name
          // item.value = item.children[0].id
          // item.valueId = item.children[0].id
          this.treeselectDeep(item, item.children)
        }
        arr.push([item])
      }
      return arr
    },
    treeselectDeep(parent, item) {
      if (Array.isArray(item)) {
        // 数组
        for (let i = 0; i < item.length; i++) {
          if (item[i].children) {
            this.treeselectDeep(item[i], item[i].children)
            parent.valueId = item[i].valueId
            parent.label = item[i].label
          } else {
            // 子节点
            // item[i].valueId = item[i].children[0].id
            // item[i].label = item[i].children[0].name
            parent.valueId = item[i].id
            parent.label = item[i].name
            // if (item[i].icon) {
            //   this.classifyIcons.push(item[i].icon)
            // }
            item[i].icon && this.classifyIcons.push(item[i].icon)
          }
        }
      } else if (item?.constructor === Object) {
        // 对象
        if (item.children && !Array.isArray(item)) {
          this.a(item, item.children)
        } else if (!item.children && !Array.isArray(item)) {
          parent.valueId = parent.children[0].id
          parent.label = parent.children[0].name
        }
      }
    },
    /** 转换剂型树形数据结构 */
    normalizerTreeNode(node) {
      if (!(node.children && node.children.length > 0)) {
        delete node.children
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      }
    },
    // 制剂属性
    async fetchPropertyData() {
      const obj = {
        drugProdId: this.drugProdId
      }
      let routeArr = []
      let siteArr = []
      this.propertyObj.routeStr = ''
      this.propertyObj.siteStr = ''
      let res = await getPropertyApi(obj)
      if (res.code === 200) {
        this.propertyObj.typeDesc = res.data && res.data.typeDesc // 剂型
        this.propertyObj.ppRouteBeanList = (res.data && res.data.ppRouteBeanList) || []
        this.propertyObj.ppSiteBeanList = (res.data && res.data.ppSiteBeanList) || []
        this.ppSpecBeanList = (res.data && res.data.ppSpecBeanList) || []
        this.propertyObj.ppRouteBeanList.forEach(item => {
          routeArr.push(item.routeName)
        })
        this.propertyObj.ppSiteBeanList.forEach(item => {
          siteArr.push(item.siteName)
        })
        this.propertyObj.routeStr = routeArr.join(',')
        this.propertyObj.siteStr = siteArr.join(',')
        this.propertyObj.drugProcMethodBean = res.data.drugProcMethodBean && res.data.drugProcMethodBean.conceptVal
        this.propertyObj.drugTypeSpecsBean = res.data.drugTypeSpecsBean && res.data.drugTypeSpecsBean.conceptVal
      }
    },
    // 同制剂规格药品 调接口
    async fetchDrugData() {
      
      let relatedDrugRes = await getDrugDataApi(this.drugObj)
      if (relatedDrugRes.code === 200) {
        let drugTableData = relatedDrugRes.rows || []
        let ingredientArr = []
        drugTableData.forEach(item => {
          let manufacturerArr = []
          item.dkbDpSpecIngredientList.forEach(ingredient => {
            ingredientArr.push({
              substanceName: ingredient.substanceName,
              substanceId: ingredient.substanceId
            })
          })
          let primaryDrugItem = item.dkbDpAliasList.find(item => item.isPrimary === '1')
          let drugName = ''
          if (primaryDrugItem) {
            drugName = primaryDrugItem.alias
          } else {
            if (item.dkbDpAliasList && item.dkbDpAliasList.length > 0) {
              drugName = item.dkbDpAliasList[0].alias
            }
          }
          item.drugName = drugName
          item.ingredientStr = ingredientArr.join('+') // 成分
          item.dkbDpManufacturerList.forEach(manufacture => {
            manufacturerArr.push(manufacture.manufacturerName)
          })
          item.manufacturerStr = manufacturerArr.join('\n')
          /* {{ scope.row.substanceQtyMax }}
          {{ scope.row.substanceQtyUnitDesc }} */
        })
        this.sameTableData = drugTableData
        this.drugObj.total = relatedDrugRes.total
      }
    },
    // 同类药物制剂调接口
    async fetchSimilarData(scroll = false) {
      // let similarRes = await getSimilarDataApi(this.similarObj)
      // if (similarRes.code === 200) {
      //   if (!scroll) {
      //     this.similarTableData = similarRes.rows || []
      //   } else {
      //     this.similarTableData = this.similarTableData.concat(similarRes.rows)
      //   }
      //   this.similarObj.total = similarRes.total
      // }
      $request.defaults.baseURL = 'http://localhost:8080'
      $request.post('/preparation/getSimilar', this.similarObj).then(similarRes => {
        console.log(similarRes)
        if (!scroll) {
          this.similarTableData = similarRes.rows || []
        } else {
          this.similarTableData = this.similarTableData.concat(similarRes.rows)
        }
        this.similarObj.total = similarRes.total
      })
    },
    // 跳转到对应的查看页面
    goToLink(row, type) {
      if (type === 'preparation') {
        // 制剂查看
        this.$router.push('/preparationSpecification/view/' + row.preparationId)
      } else if (type === 'substance') {
        // 物质查看
        this.$router.push('/substance/search/' + row.substanceId)
      } else if (type === 'drug') {
        // 药物查看
        this.$router.push('/drug/search/' + this.drugId)
      } else if (type === 'drugProd') {
        // 药品查看
        this.$router.push('/drugProd/view/' + row.id)
      }
    },
    refresh() {
      this.reload = false
      this.$nextTick(() => (this.reload = true))
    },
    // tab click
    jump(index, info) {
      let target = document.querySelector('.scroll-content')
      let scrollItems = document.querySelectorAll('.scroll-item')
      // 判断滚动条是否滚动到底部
      if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
        this.tabIndex = index.index.toString()
      }
      let totalY = scrollItems[index.index].offsetTop - scrollItems[0].offsetTop // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)
      let distance = document.querySelector('.scroll-content').scrollTop // 滚动条距离滚动区域顶部的距离
      // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)
      // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次
      // 计算每一小段的距离
      let step = totalY / 50
      if (totalY > distance) {
        smoothDown(document.querySelector('.scroll-content'))
      } else {
        let newTotal = distance - totalY
        step = newTotal / 50
        smoothUp(document.querySelector('.scroll-content'))
      }

      // 参数element为滚动区域
      function smoothDown(element) {
        if (distance < totalY) {
          distance += step
          element.scrollTop = distance
          setTimeout(smoothDown.bind(this, element), 10)
        } else {
          element.scrollTop = totalY
        }
      }

      // 参数element为滚动区域
      function smoothUp(element) {
        if (distance > totalY) {
          distance -= step
          element.scrollTop = distance
          setTimeout(smoothUp.bind(this, element), 10)
        } else {
          element.scrollTop = totalY
        }
      }
    },
    // 滚动条滚动
    onScroll(e) {
      let scrollItems = document.querySelectorAll('.scroll-item')
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop - 100
        if (judge) {
          this.tabIndex = i.toString()
          break
        }
      }
    },
    getHight() {
      this.contentStyleObj.height = (window.innerHeight - 190) + 'px'
    },
    //点击节点
    handleNodeClick(data) {
      this.node = data;
      // this.record.parentCode = data.id;
      this.$refs.treeForm.setCheckedKeys([data.id]);
    },
    //节点选中状态变化
    handleClick(data, checked, node){
      if(checked){
        this.node = data;
        // this.record.parentCode = data.id;
        this.$refs.treeForm.setCheckedKeys([data.id]);
      } else {
        this.node = {};
        // this.record.parentCode = '';
        this.$refs.treeForm.setCheckedKeys([]);
      }
    },
    //点击复选框
    handleNodeCheck(data, checked) {
      if (checked.checkedKeys.length > 0) {
        this.node = data;
        // this.record.parentCode = data.id;
        this.$refs.treeForm.setCheckedKeys([data.id]);
      } else {
        this.node = {};
        // this.record.parentCode = '';
        this.$refs.treeForm.setCheckedKeys([]);
      }
    },
    toDrugUseInstruction() {
      this.$router.push('/drugProd/drugUseInstruction')
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.getHight)
  },
}
</script>
<style lang="scss" scoped>
.drugProd-wrap {
  background: #EBEFF7;
  .el-form-item{
    line-height: 20px!important;
    font-size: 14px;
    font-family: 'pingfang-regular';
    font-weight: 400;
    color: #333333;
    margin-bottom: 10px !important;
  }

  .el-form-item__label{
    line-height: 30px!important;
  }
  .el-form-item__content{
    line-height: 30px!important;
  }
  .el-tabs__header{
    margin-bottom: 0px!important;
  }
}
</style>
<style lang="scss" scoped>
  .el-tree-node__content:hover, .el-tree-node__content:focus {
    background-color: transparent !important;
  }
  .el-tree-node__content {
    background-color: transparent !important;
  }
  .el-tree-node .is-current {
    background-color: transparent !important;
  }
  .content {
    margin-left: 10px !important;
    padding-left: 20px !important;
  }
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .classify-li {
    margin: 10px !important;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  .classify-tree {
    border: 1px solid #638BF7;
    border-radius: 15px;
    padding: 0 5px;
    min-height: 52px;
  }
  .content.bacg {
    margin-top: 10px;
  }
.link {
  font-size: 14px;
  font-family: 'pingfang-regular';
  font-weight: 400;
  text-decoration: underline;
  color: #2B60F8;
  cursor: pointer;
}
.unit-link {
  color: #333;
  cursor: pointer;
  text-decoration: underline;
}
.substanceName{
  height: 40px;
  line-height: 40px;
  background: #FFFFFF;
  box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
  border-radius: 4px;
  span{
    font-size: 16px;
    color: #333333;
    /* font-family: 'pingfang-regular'; */
    font-weight: 600;
  }
  //.el-icon-info{
  //  color: #E99B00;
  //  font-size: 16px;
  //  margin:0px 5px 0px 20px;
  //}
}
.el-icon-info{
  color: #E99B00;
  font-size: 16px;
  margin-right:5px;
}
.substanceStructure{
  height: 40px;
  height: auto!important;
  min-height: 40px;
  background: #FFFFFF;
  box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
  border-radius: 4px;
  margin: 10px 0px;
  padding-bottom: 20px;
}
.substanceStructure.bacg-el-row {
  padding-bottom: 10px;
}
.substanceTitle{
  font-size: 16px;
  /* font-family: 'pingfang-regular'; */
  font-weight: 600;
  padding:16px 0px 5px 19px;
  color: #333333;
}
ul,li{ padding:0;margin:0;list-style:none}
li.background-li{
  float: left;
  margin-right: 5px;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  background: #E6ECFE;
  border-radius: 3px;
  padding:0px 10px;
}
.el-form-item-li {
  margin-bottom: 0px !important;
}
li.no-data-li {
  color: #999999;
  font-size: 14px;
}
.substanceTable{
  padding:10px 20px 0px;
}
</style>
