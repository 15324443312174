/**药品查看页面 */
import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'
// 药品:根据药品ID查询药品明细（药品名称+药品成分+药品企业+药品标示+药品规格包装）
export function getBasicInfoApi(id, searchValue) {
  return request({
    url: '/dkm/drugProd/info/' + praseStrEmpty(id),
    method: 'get',
    params: { drugProdId: searchValue }
  })
}
//药品:根据药品id查询所属药物信息 (药物组+中西草标示)
export function getDrugInfoApi(data) {
  return request({
    url: '/dkm/drugProd/selectDrugByDrugProdId',
    method: 'post',
    params: data
  })
}
// 制剂分类
export function getClassifyApi(data) {
  return request({
    url: '/dkm/category/dir/getDrugCategoryTree',
    method: 'post',
    params: data
  })
}
// 药品:根据药品id查询所属制剂信息 (制剂属性)
export function getPropertyApi(data) {
  return request({
    url: '/dkm/drugProd/selectPpByDrugProdId',
    method: 'post',
    params: data
  })
}
// 药品:根据药品id查询同制剂规格的药品列表（排除自己）（同制剂规格药品）
export function getDrugDataApi(data) {
  return request({
    url: '/dkm/drugProd/selectSamePpSpecDrugProd',
    method: 'post',
    params: data
  })
}
